import './App.css';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink, split } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';

import SideBar from './layout/sideBar';
import Project from './pages/project';
import Home from './pages/home';
import Present from './pages/present';
import Join from './pages/join';
import Login from './auth/login';
import SignUp from './auth/signup';
import Landing from './pages/landing';
import WaitList from './auth/waitList';
import Settings from './pages/settings';

function App() {
  const user = localStorage.getItem("jwtToken")
  const httpLink = new HttpLink({
    // uri: 'http://localhost:4000/graphql',
    uri: 'https://server.xand.app/graphql',
    headers: {
      Authorization: user || '',
    }
  });

  const wsLink = new GraphQLWsLink(createClient({
    // url: `ws://localhost:4000/graphql`,
    url: `wss://server.xand.app/graphql`
  }));

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink,
  );

  const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache()
  });

  return (
    <ApolloProvider client={client}>
      <Router>
        <Website user={user}/>
      </Router>
    </ApolloProvider>
  );
}

function Website({user}){
  const location = useLocation();
  const background = location.state && location.state.background;

  return(
    <div className="App">
      {location.pathname !== "/join" && location.pathname !== "/live"?<SideBar user={user}/>: null}
      <Routes location={background || location}>
      {user?
      <>
        <Route path='*' element={<Navigate to={"/"}/>} />
        <Route path='/' element={<Home />} />
        <Route path='/presentation/:presentId' element={<Project/>} />
        <Route path='/join' element={<Join/>} />
        <Route path='/live' element={<Present/>} />
        <Route path='/settings' element={<Settings/>} />
      </>
      :
      <>
        <Route path='*' element={<Navigate to={"/"}/>} />
        <Route path='/' element={<Landing/>} />
        <Route path='/signin' element={<Login/>} />
        <Route path='/signup' element={<SignUp/>} />
        <Route path='/join' element={<Join/>} />
        <Route path='/live' element={<Present/>} />
        <Route path='/waitlist' element={<WaitList/>} />
      </>
      }
      </Routes>
    </div>
  )
}

export default App;