import React from 'react';
import './style.css'

export default function Settings() {

  return (
    <div className='Settings'>
      
    </div>
  )
}