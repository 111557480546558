import { gql } from "@apollo/client";

export const CREATE_PRESENT = gql`
mutation CreatePresentation($presentation: PresentationInput) {
  createPresentation(presentation: $presentation) {
    uuid
  }
}`

export const CREATE_SLIDE = gql`
mutation CreateSlide($slide: SlideInput) {
  createSlide(slide: $slide) {
    _id
  }
}`

export const UPDATE_SLIDE = gql`
mutation Mutation($slide: SlideInput) {
  updateSlide(slide: $slide) {
    title
  }
}`

export const CREATE_LIVE = gql`
mutation CreateLive($live: LiveInput) {
  createLive(live: $live) {
    isLive
  }
}`

export const UPDATE_LIVE = gql`
mutation UpdateLive($live: LiveInput) {
  updateLive(live: $live) {
    isLive
  }
}`

export const ON_LOGIN = gql`
mutation OnLogin ($login:LoginInput){
  onLogin(login:$login)
}`

export const ON_SIGNUP = gql`
mutation CreateUser ($user:UserInput){
  createUser(user:$user)
}
`

export const CREATE_WAIT = gql`
mutation CreateWaitlist($name: String, $email: String) {
  createWaitlist(name: $name, email: $email)
}`

export const DELETE_SLIDE = gql`
mutation DeleteSlide($deleteSlideId: ID!) {
  deleteSlide(id: $deleteSlideId)
}`

export const CREATE_TEMP_USER = gql`
mutation CreateTempUser($user: TempUserInput) {
  createTempUser(user: $user) {
    _id
  }
}`

export const UPDATE_TEMP_USER = gql`
mutation UpdateTempUser($user: TempUserInput) {
  updateTempUser(user: $user) {
    _id
  }
}`

export const UPDATE_PRESENT = gql`
mutation UpdatePresentation($presentation: PresentationInput) {
  updatePresentation(presentation: $presentation) {
    uuid
  }
}`

export const CREATE_ANSWER = gql`
mutation CreateAnswer($answer: AnswerInput) {
  createAnswer(answer: $answer) {
    _id
    response
  }
}`

export const UPDATE_USER = gql`
mutation UpdateUser($user: UserInput) {
  updateUser(user: $user) {
    name
  }
}`