import React, { useEffect, useState } from 'react'
import './style.css'
import { useLazyQuery, useMutation } from '@apollo/client'
import { FIND_CODE, GET_PRESENTATION } from '../graphql/query'
import { UPDATE_PRESENT } from '../graphql/mutation'

export default function Settings({open, setOpen, data}) {
  const [title, setTitle] = useState("")
  const [code, setCode] = useState("")
  const [error, setError] = useState("")

  useEffect(()=>{
    setTitle(data?.title)
    setCode(data?.code)
  },[data])

  const[findCode] = useLazyQuery(FIND_CODE)
  const[update,{loading}] = useMutation(UPDATE_PRESENT,{
    refetchQueries:[
        {query:GET_PRESENTATION,variables:{"getPresentationId":data?.uuid}}
    ]
  })

  function OnUpdate(){
    update({
        variables:{
            "presentation": {
                "background": data?.background,
                "code": code,
                "id": data?._id,
                "template": data?.template,
                "title": title
            }
        }
    }).then(()=>{
        setOpen(false)
    })
  }

  function OnFindCode(){
    if(code!==data?.code){
        findCode({
            variables: {"findCodeId":code}
        }).then((dat)=>{
            if(dat?.data?.findCode >0){
                setError("Code already taken")
            }
        })
    }
  }

  return (
    <div className={open? 'Modal1 Modal_Show' : 'Modal1'}>
        <div className='Modal_Con2'>
            <div className='Modal_Body'>
                <h1>Settings</h1>
                <div className='Modal_Sett_InpCon'>
                    <div className='Modal_Sett_Inp'>
                        <p>Title</p>
                        <input value={title} onChange={(e)=>setTitle(e.target.value)} placeholder='Presentation Title'/>
                    </div>
                    <div className='Modal_Sett_Inp'>
                        <p>Code</p>
                        <input onBlur={()=>OnFindCode()} value={code} onChange={(e)=>setCode(e.target.value)} placeholder='Presentation Code'/>
                    </div>
                </div>
                <div style={{marginTop:10}}/>
            </div>
            {error?<p className='Modal_Error'>{error}</p>:null}
            <div className='Modal_BtnCon'>
                <button className='Modal_Btn2' onClick={()=>setOpen(false)}>Delete</button>
                <div className='FlexCon'/>
                <button className='Modal_Btn' onClick={()=>setOpen(false)}>Cancel</button>
                <button className='Modal_Btn1' style={{opacity: title!==data?.title || code!==data?.code?1:null}} onClick={()=>OnUpdate()}>{loading? "Loading" : "Save"}</button>
            </div>
        </div>
    </div>
  )
}
