import React, { useState } from 'react'
import "./style.css"
import { Link, useNavigate } from 'react-router-dom'
import { ON_SIGNUP } from '../graphql/mutation'
import { useMutation } from '@apollo/client'

export default function SignUp() {
  const navigate = useNavigate()
  document.title = "Sign Up | XAND"

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")

  const [signup,{loading}] = useMutation(ON_SIGNUP)

  function OnSignup(event){
    event.preventDefault()
    signup({
      variables:{
        "user":{
          "name": email?.split('@')[0],
          "email": email,
          "password": password,
        }
      }
    }).then((e)=>{
      if(e.data?.createUser){
        const token = e.data?.createUser
        localStorage.setItem('jwtToken', token)
        window.location.reload()
      }else if(e?.errors?.length>0){
        setError(e?.errors[0]?.message)
        console.log(e?.errors[0]?.message);
      }
    })
  }

  return (
    <div className='Login'>
      <div className='Login_Left'></div>
      <div className='Login_Right'>
        <div className='Login_Right_Con'>
          <h1>Nice to meet you 🤝</h1>
          <h2>Create your account</h2>
          <form onSubmit={()=>navigate(`/loading`)}>
            <input value={email} onChange={(e)=>setEmail(e.target.value)} type='email' placeholder='Email Address'/>
            <input value={password} onChange={(e)=>setPassword(e.target.value)} style={{marginTop:15}} type='password' placeholder='Create a Password'/>
            <button onClick={(event)=>OnSignup(event)}>{loading?"Loading":"Sign Up"}</button>
          </form>
        </div>
        <p>Already have an account? <Link to={'/signin'}>Login</Link></p>
      </div>
    </div>
  )
}
