import React,{ useEffect, useState, useRef } from 'react'
import "./style.css"
import QRCode from "react-qr-code";
import { CaretLeft, CaretRight, Info, X } from '@phosphor-icons/react';
import { gql, useLazyQuery, useMutation, useSubscription, useApolloClient } from '@apollo/client';
import { UPDATE_LIVE } from '../graphql/mutation';
import { GET_ALL_SLIDE, GET_PRESENT_USERS } from '../graphql/query';

export default function Slide({open, data, presentId, datas, select, setSelect, code}) {
  const client = useApolloClient();

  // const videoRef = useRef(null);
  // const [isCameraOn, setIsCameraOn] = useState(true);

  // useEffect(() => {
  //   const constraints = { video: true };

  //   const handleSuccess = (stream) => {
  //     if (videoRef.current) {
  //       videoRef.current.srcObject = stream;
  //       videoRef.current.addEventListener('enterpictureinpicture', handlePiP);
  //     }
  //   };

  //   const handleError = (error) => {
  //     console.error('Error accessing camera:', error);
  //   };

  //   if (isCameraOn) {
  //     navigator.mediaDevices.getUserMedia(constraints)
  //       .then(handleSuccess)
  //       .catch(handleError);
  //   } else {
  //     if (videoRef.current && videoRef.current.srcObject) {
  //       videoRef.current.srcObject.getTracks().forEach(track => track.stop());
  //     }
  //   }

  //   return () => {
  //     if (videoRef.current && videoRef.current.srcObject) {
  //       videoRef.current.srcObject.getTracks().forEach(track => track.stop());
  //     }
  //   };
  // }, [isCameraOn]);

  // const handleToggleCamera = () => {
  //   setIsCameraOn(prevState => !prevState);
  // };

  // const handlePiP = (event) => {
  //   event.preventDefault();
  // };

  const [index, setIndex] = useState(0)

  useEffect(() => {
    if(open){
    const handleKeyPress = (event) => {
      if (event.key === 'ArrowLeft' && select !== 0) {
        setSelect((prevIndex) => prevIndex - 1)
      } else if (event.key === 'ArrowRight' && select < data?.length - 1) {
        setSelect((prevIndex) => prevIndex + 1)
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    }
   }
  }, [select, open]);

  useEffect(()=>{
      setIndex(select)
      setQuesNo(0)
  },[select])

  const [quesNo, setQuesNo] = useState(0)

  const titleStyle = data[index]?.titleStyle? data[index]?.titleStyle: "";
  const discrStyle = data[index]?.discrStyle? data[index]?.discrStyle: "";

  const regex = /f-s:[^;]+/;

  function FontChange(val){
    const match = val?.match(regex);
    if (match) {
      const currentFontSize = match[0].split(":")[1];
      const newFontSize = parseFloat(currentFontSize) * 1.75;
      const newVal = val.replace(regex, `f-s:${newFontSize}vw`);
      return newVal
    } else {
      return titleStyle;
    }
  }

  const titleStyleDeclare = FontChange(titleStyle).split(";");
  const discrStyleDeclare = FontChange(discrStyle).split(";");

  const styleTitle = titleStyleDeclare.reduce((result, declaration) => {
    const [property, value] = declaration.split(":");
    if (property && value) {
      const convertedProperty = convertProperty(property);
      const convertedValue = convertValue(value);
      result[convertedProperty] = convertedValue;
    }
    return result;
  }, {});

  const styleDiscr = discrStyleDeclare.reduce((result, declaration) => {
    const [property, value] = declaration.split(":");
    if (property && value) {
      const convertedProperty = convertProperty(property);
      const convertedValue = convertValue(value);
      result[convertedProperty] = convertedValue;
    }
    return result;
  }, {});

  function convertProperty(property) {
    switch (property) {
      case "t-a":
        return "textAlign";
      case "f-s":
        return "fontSize";
      default:
        return property;
    }
  }

  function convertValue(value) {
    if(isNaN(value)){
      return value;
    }else{
      return parseInt(value);
    }
  }

  function isImg(vals){
    const val = vals?.match(/\b((?:https?|ftp):\/\/[^\s/$.?#].[^\s]*)/g)
    if(val?.length >0){
      return true
    }else{
      return false
    }
  }

  function isColor(vals){
    const val = vals?.match(/\b((?:https?|ftp):\/\/[^\s/$.?#].[^\s]*)/g)
    if(val?.length === 0){
      return true
    }else{
      return false
    }
  }

  const[createLive] = useMutation(UPDATE_LIVE)

  useEffect(()=>{
    if(datas?.getLive?.isLive){
      OnLive()
    }
  },[index])

  function OnLive(){
    createLive({
      variables:{
        "live": {
          "currentSlide": data[index]?._id,
          "id": datas?.getLive?._id? datas?.getLive?._id : "",
          "isLive": true,
          "presentation": presentId,
          "slide":""
        }
      }
    }).then((e)=>{
      console.log(e.data);
    })
  }

  const [active, setActive] = useState(0)
  const [info, setInfo] = useState(true)

  useEffect(()=>{
    setTimeout(function () {
      setActive(index)
    }, 200)    
  },[index])

  const styles = data[active]?.titlestyle?.split(';') || []
  
  function textAlign(){
    if(styles[3] === 'Left'){
      return "flex-start"
    }else if(styles[3] === 'Right'){
      return "flex-end"
    }else{
      return "Center"
    }
  }

  const [users, setUsers] = useState([])

  const[getUsers] = useLazyQuery(GET_PRESENT_USERS)

  useEffect(()=>{
    if(code){
      getUsers({
        variables:{
          "getTempUsersId": code
        }
      }).then((data)=>{
        console.log(data?.data?.getTempUsers);
        setUsers(data?.data?.getTempUsers)
      })
    }
  },[code])

  const USER_JOIN = gql`
  subscription UserJoin($presentId: ID) {
    userJoin(presentId: $presentId) {
      _id
      image
      name
    }
  }`

  const userJoin = useSubscription(USER_JOIN,{
    variables:{
      "presentId": code
    }
  })

  useEffect(()=>{
    if(userJoin?.data){
      console.log("users",users);
      console.log("recived",userJoin?.data);
      setUsers([...users, userJoin?.data?.userJoin])
    }
  },[userJoin])

  const ANS_CREATE = gql`
  subscription Subscription($presentId: ID) {
    answerCreated(presentId: $presentId) {
      _id
      response
      slideId
    }
  }
  `
  const newAns = useSubscription(ANS_CREATE,{
    variables:{
      "presentId": data[active]?._id
    }
  })

  useEffect(()=>{
    if(newAns?.data){
      const otherAns = data?.filter(e=>e?._id === newAns?.data?.answerCreated?.slideId)[0]?.answers
      client.writeQuery({
        query: GET_ALL_SLIDE,
        variables:{
          "getSlideId": data[active]?._id
        },
        data:{
          getSlide:{
            __typename:"Slide",
            _id:newAns?.data?.answerCreated?.slideId,
            answers:[
              ...otherAns,
              {
                __typename:"Answer",
                _id: newAns?.data?.answerCreated?._id,
                done:false,
                response: newAns?.data?.answerCreated?.response
              }
            ]
          }
        }
      })
    }
  },[newAns])

  const getRandomFontSize = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min) + 'vw';
  };

  return (
    <div className={open? 'Modal Modal_Show' : 'Modal'}>
      {info?
        <div className='Modal_Info'>
          <div className='Modal_Info_Left'>
            <div className='Modal_Info_QR'>
              <h4>Scan QR Code to join</h4>
              <QRCode style={{margin:"25px"}} value={`http://xand.app/live?id=${code}`} size={"25vw"}/>
              <div className='Modal_Info_QR_SepCon'>
                <div className='Modal_Info_QR_SepCircle'/>
                <div className='Modal_Info_QR_Sep'/>
                <div className='Modal_Info_QR_SepCircle'/> 
              </div>
              <h4>Or go to <b>xand.app/join</b></h4>
              <h5>code: <b>{code}</b></h5>
            </div>
          </div>
          <div className='Modal_Info_Right'>
            <h4>Users joined <div className='Modal_Info_Right_Badge'>{users?.length}</div></h4>
            {users?.map((item, index)=>{
              return <h5 key={index}><b>{item?.image}</b> {item?.name}</h5>
            })}
            <button onClick={()=>setInfo(false)}>Start Presentation</button>
          </div>
          {/* <div className='Modal_Info_Close' onClick={()=>setInfo(false)}>
            <X weight='bold'/>
          </div> */}
        </div>
        :
        <div className={`Modal_Con ${index === active? "active" : ""}`}  style={{backgroundImage:isImg(data[active]?.background)? `url(${data[index]?.background})` : isImg(datas?.getPresentation?.background)?`url(${datas?.getPresentation?.background})`:null, backgroundColor: isColor(data[active]?.background)? data[active]?.background : isColor(datas?.getPresentation?.background)? datas?.getPresentation?.background : null}}>
            {/* <p>Go to <b>xand.app</b> and use the code <b>{presentId}</b></p> */}
            {data[active]?.type === "Info" ||data[active]?.type === "Video"?
              null
              :
              <>
              {!!data[active]?.title&&<h1 style={{color:styles[2], alignSelf:textAlign(), fontFamily:styles[0], fontSize:`${styles[1] * 0.16}vw`}} onClick={()=>console.log(data)}>{data[active].title}</h1>}
              {data[active]?.title&&data[active]?.discr?<div style={{marginTop:data[active].title?"1.35vw":null}}/>:null}
              {!!data[active]?.discr&&<h2 style={styleDiscr}>{data[active].discr}</h2>}
              </>
            }
            {data[active]?.type === "Poll"?
                <div className='Modal_Poll_Con'>
                {data[active]?.options?.map((item, index)=>{
                  const allAns = data[active]?.answers?.length>0? data[active]?.answers?.length: 1
                  const length = data[active]?.answers?.filter(e=> e?.response === item)?.length
                  const precent = length/allAns * 100
                  return(
                    <div key={index} className='Modal_Poll_Con1'>
                      <div className='Modal_Poll'>
                      {length} ({precent?.toFixed(1)}%)
                      <div className='Modal_Graph' style={{height:`${length/allAns * 100}%`}}/>
                      </div>
                      <h3 onClick={()=>console.log(length/allAns * 100)}>{item?item:"--"}</h3>
                    </div>
                  )
                })}
                </div>
            : null}
            {data[active]?.type === "Q&A"?
             <div className={data[active]?.answers?.length>0?'Modal_Question Modal_Question_Show':'Modal_Question'}>
              <h4>{quesNo+1}. {data[active]?.answers[quesNo]?.response}</h4>
              <div className='Modal_Ques_Arrow'>
                <CaretLeft className='Modal_Arrow' weight='bold' size={20} onClick={()=>quesNo!==0?setQuesNo(quesNo - 1):null}/>
                <h2>{quesNo+1}/{data[active]?.answers?.length}</h2>
                <CaretRight className='Modal_Arrow' weight='bold' size={20} onClick={()=>quesNo===data[active]?.answers?.length -1? null : setQuesNo(quesNo+1)}/>
              </div>
             </div>
              : null
            }
            {data[active]?.type === "Info"?
            <div className='Modal_Mobile'>
              <QRCode value={data[active]?.title} size={"10vw"}/>
            </div>
            : null}
            {data[active]?.type === 'Cloud'?
            <div className='Modal_Cloud'>
              {data[active]?.answers?.length>0?
              <ul>
                  {data[active]?.answers?.map((item, index)=>{
                    const size = getRandomFontSize(3, 4)
                      return <li key={index} style={{fontSize:size, color: (index+1)%5 === 0? "#ffcc4e" : (index+1)%4 === 0? "#1a6dff" : (index+1)%3 === 0? "#db492a" : (index+1)%2 === 0? "#ff80ab":"#00da8d" }}>{item?.response}</li>
                  })}
              </ul>
              : null}
            </div>
            : null}
            {data[active]?.type === "Video"?
            <iframe width="100%" height="100%" src={`https://www.youtube.com/embed/${data[active]?.title?.split('=')[1]}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            : null
            }
            <div className='Modal_ArrowCon'>
              <Info size={20} className='Modal_Arrow' weight='bold' onClick={()=>setInfo(true)}/>
              <CaretLeft className='Modal_Arrow' weight='bold' size={20} onClick={()=>index!==0?setSelect(index - 1):null}/>
              <h2>{index+1}/{data?.length}</h2>
              <CaretRight className='Modal_Arrow' weight='bold' size={20} onClick={()=>index < data?.length - 1?setSelect(index + 1):null}/>
            </div>
            {/* <video onClick={handleToggleCamera} ref={videoRef} autoPlay playsInline style={{ width: 200, height: 200, borderRadius:100, objectFit:"cover" }} /> */}
        </div>
      }
    </div>
  )
}