import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { List } from '@phosphor-icons/react';

export default function Landing() {
  const navigate = useNavigate()
  document.title = "XAND"
  return (
    <div className='Landing'>
      <div className='Landing_Nav'>
        <h1>XAND</h1>
        <div className='FlexCon'/>
        {/* <div className='Landing_Nav_Menu'>About</div>
        <div className='Landing_Nav_Menu'>Features</div>
        <div className='Landing_Nav_Menu'>Plans</div> */}
        {/* <Link to={'/join'} className='Landing_Nav_Menu'>Join</Link> */}
        <button className='Landing_Nav_Btn1' onClick={()=>navigate('/signin')}>Sign In</button>
        <button className='Landing_Nav_Btn2' onClick={()=>navigate('/waitlist')}>Join Waitlist</button>
        <List size={23} className='Landing_Nav_Icon'/>
      </div>
      <div className='Landing_Hero'>
        <h1>Let's make every <b>presentation</b> an<br/>unforgettable experience.</h1>
        <h2>Let's make every <b>presentation</b> an unforgettable experience.</h2>
        <div className='Landing_Hero_Img'/>
        <div className='Landing_Hero_Design1'/>
        <div className='Landing_Hero_Design2'/>
      </div>
      <div className='Landing_About'>

      </div>
    </div>
  )
}
