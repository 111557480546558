import React, { useState } from 'react'
import './style.css'
import { CaretDown } from '@phosphor-icons/react'

export default function Select({data, title, onChange, style, font, range}) {
    const [open, setOpen] = useState(false)
    function generateNumbersBetween(start, end) {
        return Array.from({ length: end - start + 1 }, (_, index) => start + index);
    }
    return (
        <div className='Select_Con' style={style} onClick={() => setOpen(!open)} tabIndex={0} onBlur={(e) => {
            if (e.currentTarget.contains(e.relatedTarget)) {
                return
            }
            setOpen(false)
        }}>
            <div className='Select' onClick={() => setOpen(!open)}>
                <p>{title}</p>
                <CaretDown className={open ? 'Select_Icon Select_Icon_Open' : 'Select_Icon'} />
            </div>
            <div className={open? 'Select_Modal Select_Modal_Open' : 'Select_Modal'}>
                {range?
                generateNumbersBetween(range[0],range[1])?.map((item, index)=>{
                    return(
                        <div key={index} className='Select_Modal_Item' onClick={()=>onChange(item)}>
                            {item}
                        </div>
                    )
                })
                :
                data?.map((item, index)=>{
                    return(
                        <div key={index} style={{fontFamily:font?item?.value: null}} className='Select_Modal_Item' onClick={()=>onChange(item?.value)}>
                            {item?.name}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
