import React from 'react'
import "./style.css"
import { SquaresFour, Gear, ProjectorScreen, UserCircle, Headset, SignOut } from "@phosphor-icons/react";
import { Link, useLocation } from 'react-router-dom';

export default function SideBar({user}) {
  const location = useLocation()
  function OnLogout(){
    localStorage.removeItem("jwtToken")
    window.location.reload()
  }
  if(user){
  return (
    <div className='SideBar'>
        <div className='Logo'>
            <Link to={"/"}>
              <ProjectorScreen weight='fill' color='#2e66ee' size={22}/>
            </Link>
        </div>
        <ul>
            <li>
              <Link to={"/"} style={{color:location.pathname === "/"? '#000' : '#666'}}>
                <SquaresFour weight={location.pathname === "/"? 'fill' : 'regular'} size={22}/>
              </Link>
            </li>
            <li>
              <Link to={"/settings"} style={{color:location.pathname === "/settings"? '#000' : '#666'}}> 
                <Gear weight={location.pathname === "/settings"? 'fill' : 'regular'} size={22}/>
              </Link>
            </li>
            <li>
              <Link to={"/support"} style={{color:location.pathname === "/support"? '#000' : '#666'}}>
                <Headset weight={location.pathname === "/support"? 'fill' : 'regular'} size={22}/>
              </Link>
            </li>
            <li>
              <Link to={"/account"} style={{color:location.pathname === "/account"? '#000' : '#666'}}>
                <UserCircle weight={location.pathname === "/account"? 'fill' : 'regular'} size={22}/>
              </Link>
            </li>
        </ul>
        <div className='Logo' onClick={()=>OnLogout()}>
          <SignOut weight='fill' color='#000' size={22}/>
        </div>
    </div>
  )
  }else{
    return null
  }
}
