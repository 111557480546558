import React, { useState } from 'react'
import "./style.css"
import { useNavigate } from 'react-router-dom'

export default function Join() {
  const navigate = useNavigate()
  const [code, setCode] = useState("")
  document.title = "Join | XAND"
  return (
    <div className='Join'>
      <div className='Join_Left'></div>
      <div className='Join_Right'>
        <div className='Join_Right_Con'>
          <h1>Join a presentation</h1>
          <h2>Enter code to join the live presentation</h2>
          <form onSubmit={()=>navigate(`/live?id=${code}`)}>
          <input value={code} onChange={(e)=>setCode(e.target.value)} placeholder='Presentation ID'/>
          <button>Next</button>
          </form>
        </div>
        <p>Create your interactive presentation <a href='https://xand.app/login' target='_blank'>here</a></p>
      </div>
    </div>
  )
}
