import React from 'react'
import './style.css'

export default function Alerts({title, discr, show, setShow, onclick, loading}) {
  return (
    <div className={show?'Alert Alert_Show':'Alert'}>
        <span>
            <h3>{title}</h3>
            <p>{discr}</p>
            <div className='Alert_BtnCon'>
                <button className='Alert_Btn' onClick={()=>setShow(false)}>Cancel</button>
                <button className='Alert_Btn1' onClick={()=>onclick()}>{loading? "Loading" : "Yes, Delete"}</button>
            </div>
        </span>
    </div>
  )
}
