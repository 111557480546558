import React, { useState } from 'react'
import "./style.css"
import { Bell, MagnifyingGlass, PlusCircle, Plus, DotsThreeVertical, DotsThree } from "@phosphor-icons/react";
import { Link, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { GET_USER } from '../graphql/query';
import { CREATE_PRESENT } from '../graphql/mutation';

export default function Home() {
  document.title = "Dashboard | XAND"
  const { loading, data } = useQuery(GET_USER, { variables: { limit: 1 } })

  return (
    <div className='Home'>
      <div className='Home_Head'>
        <h1>Dashboard</h1>
        <div className='FlexCon' />
        <div className='Home_Head_Search'>
          <MagnifyingGlass size={16} color='#666' />
          <input placeholder='Search' />
        </div>
        <button>Create<PlusCircle weight='fill' /></button>
      </div>
      {loading ?
        <div className='Home_Loading'>
          Loading...
        </div>
        :
        <div className='Home_Body'>
          <div className='Home_Left'>
            {/* <div className='Home_Title'>
              <h2>Popular templates</h2>
            </div>
            <div className='Home_Temp_Con'>
              <CreateTemplate/>
              {templates?.map((item, index)=>{
                return <Template key={index} data={item}/>
              })}
            </div> */}
            <div className='Home_Title'>
              <h2>Recent presentation</h2>
            </div>
              <div className='Home_Temp_Con'>
                <CreateTemplate/>
                {data?.getUser?.presentations?.map((item, index) => {
                  const styles = item?.slides[0]?.titlestyle?.split(';') || []
                  return (
                    <MyProject key={index} item={item} styles={styles}/>
                  )
                })}
              </div>
          </div>
        </div>
      }
    </div>
  )
}

function MyProject({item, styles}){
  const naviation = useNavigate()
  const [menu, setMenu] = useState(false)
  return(
    <div className='Home_Temp1'>
      <Link to={`/presentation/${item?.uuid}`} className='Home_Temp' style={{ marginLeft: 0, color:styles[2], backgroundColor: item?.slides[0]?.background, backgroundImage: `url(${item?.slides[0]?.background})` }}>
        {item?.slides[0]?.title}
      </Link>
      <div className='Home_Temp_Title'>
        <div>
          <h4>{item?.title}</h4>
        </div>
        <div tabIndex={1} onBlur={(e)=>{
            if(e.currentTarget.contains(e.relatedTarget)){
              return
            }
            setMenu(false)
          }}>
        <DotsThree className='Home_Temp1_Icon' size={20} weight='bold' onClick={()=>{
          setMenu(true)
        }}/>
        <div className={menu? 'Home_Temp_Menu Home_Temp_Menu_Show' : 'Home_Temp_Menu'}>
          <div className='Home_Temp_Menu_Con' onClick={(e)=>{
            e.preventDefault()
            naviation(`/presentation/${item?.uuid}`)
          }}>
            Open
          </div>
          <div className='Home_Temp_Menu_Con' onClick={(e)=>{
            e.preventDefault()
            navigator.clipboard.writeText(item?.code);
            setMenu(false)
          }}>
            Copy code
          </div>
          <div className='Home_Temp_Menu_Con' style={{color:"red"}}>
            Delete
          </div>
        </div>
        </div>
      </div>
    </div>
  )
}

function CreateTemplate(){
  const naviation = useNavigate()
  const [onCreate,{loading}] = useMutation(CREATE_PRESENT,{
    refetchQueries:[
      {query:GET_USER, variables: { limit: 1 }}
    ]
  })
  function Create(){
    onCreate({
      variables:{
        "presentation": {
          "background": "",
          "id": "",
          "template": "",
          "title": "Untitled"
        }
      }
    }).then((data)=>{
      naviation(`/presentation/${data?.data?.createPresentation?.uuid}`)
    })
  }
  return(
    <div className='Home_Temp1' style={{cursor:"pointer"}} onClick={()=>Create()}>
      <div className='Home_Temp' style={{ marginLeft: 0 }}>
        <Plus weight='regular' color='#2e66ee' size={40} />
      </div>
      <h4>Blank</h4>
    </div>
  )
}

function Template({data}){
  const [onCreate,{loading}] = useMutation(CREATE_PRESENT)

  return(
    <div className='Home_Temp1'>
      <div className='Home_Temp' style={{ marginLeft: 0, backgroundColor: data?.color, backgroundImage:`url(${data?.background})` }}>
        {data?.title}
      </div>
      <h4>{data?.name}</h4>
    </div>
  )
}

const templates = [
  // {name:"Professional", title:"", background:"https://image.slidesdocs.com/responsive-images/background/technology-clean-business-geometric-simple-blue-powerpoint-background_e3130f8dc2__960_540.jpg", color:""},
  // {name:"Color template", title:"The title", background:"", color:"pink"},
  // {name:"Kids", title:"This is the heading", background:"https://i.pinimg.com/736x/31/0c/63/310c63454e892ccb35941c79d648951e.jpg", color:""},
  // {name:"Classic", title:"Heading", background:"https://img.freepik.com/free-vector/green-abstract-background_125964-599.jpg", color:""},
]