import React, { useState } from 'react'
import "./style.css"
import { CREATE_WAIT } from '../graphql/mutation'
import { useMutation } from '@apollo/client'
import { List, XCircle } from '@phosphor-icons/react';

export default function WaitList() {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [modal, setModal] = useState(false)

  const [onJoin,{loading}] = useMutation(CREATE_WAIT)

  function Join(e){
    e.preventDefault()
    if(email && name){
      onJoin({
          variables:{
              "name": name,
              "email": email
          }
      }).then((data)=>{
          if(data?.data?.createWaitlist){
              setModal(true)
          }
      })
    }
  }

  return (
    <div className='Login'>
      <div className='Landing_Nav'>
        <div className='Landing_Logo'>XAND</div>
        <div className='FlexCon'/>
        {/* <div className='Landing_Nav_Menu'>About</div>
        <div className='Landing_Nav_Menu'>Features</div>
        <div className='Landing_Nav_Menu'>Plans</div> */}
        <List size={23} className='Landing_Nav_Icon'/>
      </div>
      <div className='Login_Left'></div>
      <div className='Login_Right'>
        <div className='Login_Right_Con'>
          <h1>Join the XAND Waitlist</h1>
          <h2>Enter your name and email</h2>
          <form onSubmit={(e)=>loading? null : Join(e)}>
            <input value={name} onChange={(e)=>setName(e.target.value)} placeholder='Full Name'/>
            <input value={email} style={{marginTop:15}} onChange={(e)=>setEmail(e.target.value)} type='email' placeholder='Email Address'/>
            <button>{loading?"Loading":"Join Waitlist"}</button>
          </form>
        </div>
      </div>
        <div className={ modal? 'Wait_Modal Wait_Modal_Show' : 'Wait_Modal'}>
            <div className='Wait_Modal_Con'>
                <div className='Wait_Modal_Con_Img'/>
                <h3>We have added you to our<br/>waiting list</h3>
                <h4>We'll let you know when XAND is ready.</h4>
                <XCircle className='Wait_Modal_Con_Icon' weight='fill' size={20} onClick={()=>setModal(false)}/>
            </div>
        </div>
    </div>
  )
}
