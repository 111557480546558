import React, { useEffect, useState } from 'react'
import "./style.css"
import { Link, useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { ON_LOGIN } from '../graphql/mutation'

export default function Login() {
  const navigate = useNavigate()
  document.title = "Sign In | XAND"
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const [login,{loading}] = useMutation(ON_LOGIN,{errorPolicy:"all"})

  function OnLogin(event){
    event.preventDefault()
    login({
      variables:{
        "login":{
          "email":email,
          "password":password
        }
      }
    }).then((e)=>{
      if(e.data?.onLogin){
        const token = e.data?.onLogin
        localStorage.setItem('jwtToken', token)
        window.location.reload()
      }else if(e?.errors?.length>0){
        setError(e?.errors[0]?.message)
        console.log(e?.errors[0]?.message);
      }
    })
  }

  useEffect(()=>{
    setError("")
  },[email, password])

  return (
    <div className='Login'>
      <div className='Login_Left'></div>
      <div className='Login_Right'>
        <div className='Login_Right_Con'>
          <h1>Nice to see you again 👋</h1>
          <h2>Sign In to your account</h2>
          <form onSubmit={()=>navigate(`/loading`)}>
            <input value={email} onChange={(e)=>setEmail(e.target.value)} type='email' placeholder='Email Address'/>
            <input value={password} onChange={(e)=>setPassword(e.target.value)} style={{marginTop:15}} type='password' placeholder='Password'/>
            {error?<p style={{margin:0, marginTop:15, fontSize:15, color:"red"}}>{error}</p>:null}
            <button onClick={(event)=>OnLogin(event)}>{loading? "Loading" : "Sign In"}</button>
          </form>
        </div>
        <p>Don't have an account? <Link to={'/signup'}>Sign Up</Link></p>
      </div>
    </div>
  )
}
