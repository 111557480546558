import React, { useState, useEffect } from 'react'
import "./style.css"
import { Play, PlusCircle, Image, TextAa, ChartBar, Question, QrCode, ChartDonut, Trash, Gear, Stop, ImageSquare, Video, Cloud, YoutubeLogo } from "@phosphor-icons/react";
import Slide from '../modal/slide';
import QRCode from "react-qr-code";
import { useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_PRESENTATION } from '../graphql/query';
import { CREATE_LIVE, CREATE_SLIDE, DELETE_SLIDE, UPDATE_LIVE, UPDATE_SLIDE } from '../graphql/mutation';
import Alerts from '../modal/alert';
import Select from '../components/select';
import ImageSearch from '../modal/imageSearch';
import Settings from '../modal/settings';

export default function Project() {
  const params = useParams()
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [imgSearch, setImgSearch] = useState(false)
  const [setting, setSetting] = useState(false)
  const [select, setSelect] = useState(0)
  const [newOpen, setNewOpen] = useState(false)
  const [inpFocus, setInpFocus] = useState("")
  
  const [slides, setSlides] = useState([{type:"", title:"", discr:""}])
  const[getPresent,{loading, data}] = useLazyQuery(GET_PRESENTATION)

  useEffect(()=>{
    if(params?.presentId){
      getPresent({
        variables:{
          "getPresentationId":params?.presentId
        }
      })
    }
  },[])

  document.title = data? `${data?.getPresentation?.title} | XAND`:`Presentation | XAND`

  useEffect(()=>{
    if(data){
      setSlides(data?.getPresentation?.slides?.length>0? data?.getPresentation?.slides:[{type:"", title:"", discr:""}])
    }
  },[data])

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.keyCode === 38 && select !== 0) {
        setSelect(select - 1)
      } else if (event.keyCode === 40 && select < slides?.length - 1) {
        setSelect(select + 1)
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [select]);

  const handleBgChange = (value) => {
    const updatedData = slides?.map((item, itemIndex) => {
      if (itemIndex === select) {
        return { ...item, background: value };
      }
      return item;
    });
    setSlides(updatedData);
    setImgSearch(false)
  };

  return (
    <div className='Project'>
        <div className='Project_Head'>
            <h1 onClick={()=>console.log(data)}>{loading?"Loading":data?.getPresentation?.title}</h1>
            <div className='FlexCon'/>
            <div className='Project_Head_Icon' onClick={()=>setSetting(true)}>
            <Gear weight='regular' color='#000' size={20} style={{marginRight:5}}/>
            <h2>Settings</h2>
            </div>
            <PresentBtn datas={data} setIsFullscreen={setIsFullscreen} select={slides[select]}/>
        </div>
        <div className='Project_Body'>
            <div className='Project_Left'>
                <div className='Project_Left_Btn_Con'>
                  <div style={{flex:1}} tabIndex={1} onBlur={(e)=>{
                    if(e.currentTarget.contains(e.relatedTarget)){
                      return
                    }
                    setNewOpen(false)
                  }}>
                    <button style={{opacity:data?.getLive?.isLive? 0.6 : 1}} className='Project_Left_Btn1' onClick={(e)=>data?.getLive?.isLive? null : setNewOpen(!newOpen)}>New Slide <PlusCircle weight='fill'/></button>
                    <CreateSlide newOpen={newOpen} slides={data?.getPresentation?.slides} setSelect={setSelect} data={data} presentId={params?.presentId} setNewOpen={setNewOpen}/>
                  </div>
                  {/* <button className='Project_Left_Btn2'>
                    <Gear weight='fill'/>
                  </button> */}
                </div>
                <div className='Project_Left_Body'>
                  {slides?.map((item, index)=>{
                    return(
                      <SlideSide key={index} item={item} index={index} select={select} setSelect={setSelect}/>
                    )
                  })}
                </div>
            </div>
            <div className='Project_Middle'>
              <div className='Project_Slide_Con'>
                <SlidePreview item={slides[select]} inpFocus={inpFocus} presentId={params?.presentId} data={data}/>
              </div>
            </div>
            <ProjectRight data={slides[select]} inpFocus={inpFocus} setImgSearch={setImgSearch} select={select} setSelect={setSelect} setInpFocus={setInpFocus} index={select} slides={slides} setSlides={setSlides} datas={data} allData={data?.getPresentation?.slides}/>
        </div>
        <Slide select={select} setSelect={setSelect} open={isFullscreen} data={data?.getPresentation?.slides?.length>0? data?.getPresentation?.slides:[{type:"", title:"", discr:""}]} presentId={params?.presentId} code={data?.getPresentation?.code} datas={data}/>
        <ImageSearch data={data} open={imgSearch} setOpen={setImgSearch} onChange={handleBgChange}/>
        <Settings open={setting} data={data?.getPresentation} setOpen={setSetting}/>
        {loading?
        <div className='Project_Loading'>
          Loading..
        </div>
        : null}
    </div>
  )
}

function PresentBtn({setIsFullscreen, datas, select}){
  const params = useParams()
  const LIVE_MUT = datas?.getLive || datas?.getLive?.isLive? UPDATE_LIVE : CREATE_LIVE
  const[createLive,{loading, data}] = useMutation(LIVE_MUT,{
    refetchQueries:[
      {
        query:GET_PRESENTATION,
        variables:{
          "getPresentationId":params?.presentId
        }
      }
    ]
  })
 
  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen()
        .then(() => {
          setIsFullscreen(true)
        })
        .catch((error) => console.error('Error attempting to enable full-screen mode:', error));
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen()
          .then(() => {
            setIsFullscreen(false)
          })
          .catch((error) => console.error('Error attempting to exit full-screen mode:', error));
      }
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(document.fullscreenElement !== null);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  function OnLive(){
    createLive({
      variables:{
        "live": {
          "currentSlide": select?._id,
          "id": datas?.getLive?._id? datas?.getLive?._id : "",
          "isLive": datas?.getLive?.isLive? false : true,
          "presentation": params?.presentId,
          "slide":""
        }
      }
    }).then((dat)=>{
      if(dat?.data?.updateLive?.isLive || dat?.data?.updateLive?.createLive){
        toggleFullscreen()
      }
    })
  }

  return(
    <div className='Project_Head_Btn_Con'>
      {datas?.getLive?.isLive?
      <>
        <button style={{backgroundColor:"#fc0356"}} onClick={toggleFullscreen}><b>● </b>{loading? "Loading" : "Live"}</button>
        <div style={{backgroundColor:"#fc0356"}} className='Project_Head_Arrow_Btn' onClick={()=>OnLive()}>
          <Stop weight='fill' color='#fff'/>
        </div>
      </>
      :
      <>
      <button onClick={()=>OnLive()}>{loading? "Loading" : "Go Live"}</button>
      <div className='Project_Head_Arrow_Btn' onClick={toggleFullscreen}>
        <Play weight='fill' color='#fff'/>
      </div>
      </>}
    </div>
  )
}

function SlidePreview({item, inpFocus, data}){
  const titleStyle = item?.titlestyle?item?.titlestyle:"";
  const discrStyle = item?.discrstyle?item?.discrstyle:"";

  const titleStyleDeclare = titleStyle.split(";");
  const discrStyleDeclare = discrStyle.split(";");

  const styleTitle = titleStyleDeclare.reduce((result, declaration) => {
    const [property, value] = declaration.split(":");
    if (property && value) {
      const convertedProperty = convertProperty(property);
      const convertedValue = convertValue(value);
      result[convertedProperty] = convertedValue;
    }
    return result;
  }, {});

  const styleDiscr = discrStyleDeclare.reduce((result, declaration) => {
    const [property, value] = declaration.split(":");
    if (property && value) {
      const convertedProperty = convertProperty(property);
      const convertedValue = convertValue(value);
      result[convertedProperty] = convertedValue;
    }
    return result;
  }, {});

  function convertProperty(property) {
    switch (property) {
      case "t-a":
        return "textAlign";
      case "f-s":
        return "fontSize";
      case "c-l":
        return "color"
      case "b-c":
        return "backgroundColor"
      default:
        return property;
    }
  }

  function convertValue(value) {
    if(isNaN(value)){
      return value;
    }else{
      return parseInt(value);
    }
  }

  function isImg(vals){
    const val = vals?.match(/\b((?:https?|ftp):\/\/[^\s/$.?#].[^\s]*)/g)
    if(val?.length >0){
      return true
    }else{
      return false
    }
  }

  function isColor(vals){
    const val = vals?.match(/^#(?:(?:[\da-f]{3}){1,2}|(?:[\da-f]{4}){1,2})$/i)
    if(val?.length > 0){
      return true
    }else{
      return false
    }
  }
  const styles = item?.titlestyle?.split(';') || []
  function textAlign(){
    if(styles[3] === 'Left'){
      return "flex-start"
    }else if(styles[3] === 'Right'){
      return "flex-end"
    }else{
      return "Center"
    }
  }
  const getRandomFontSize = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min) + 'px';
  };
  return(
    <div className='Project_Slide' style={{backgroundImage:isColor(item?.background)? null : isImg(item?.background)? `url(${item?.background})` : isImg(data?.getPresentation?.background)?`url(${data?.getPresentation?.background})`:null, backgroundColor: isColor(item?.background)? item?.background : isColor(data?.getPresentation?.background)? data?.getPresentation?.background : null}}>
      {/* <p onClick={()=>console.log(styleDiscr)}>Go to <b>xand.app</b> and use the code <b>{presentId}</b></p> */}
      {item?.type === "Info" || item?.type === "Video" ?
        null
        :
        <>
        {!!item?.title&&<h1 style={{color:styles[2], alignSelf:textAlign(), fontFamily:styles[0], fontSize:`${styles[1] * 0.09}vw`, outlineColor:"#255dec", outlineStyle:"solid", outlineWidth: inpFocus === 'Title'? 1 : 0 }}>{item?.title}</h1>}
        {item?.title&&item?.discr? <div style={{marginTop:"1vh"}}/> : null}
        {!!item?.discr&&<h2 style={{ outlineColor:"#255dec", outlineStyle:"solid", outlineWidth: inpFocus === 'Discr'? 1 : 0 }}>{item?.discr}</h2>}
        </>
      }
      {item?.type === "Poll"?
        <div className='Project_Slide_Poll_Con'>
          {item?.options?.map((vals, index)=>{
            return(
              <div key={index} className='Project_Slide_Poll_Con1'>
                <div className='Project_Slide_Poll'>
                  <div className='Project_Slide_Graph' style={{backgroundColor:styleDiscr?.backgroundColor}}/>
                </div>
                <h3>{vals?vals:"--"}</h3>
              </div>
            )
          })}
        </div>
      : null}
      {item?.type === "Info"?
      <div className='Project_Slide_Mobile'>
        <QRCode value={item?.title} size={"6vw"}/>
      </div>
      : null}
      {item?.type === 'Cloud'?
      <div className='Project_Cloud'>
        <ul>
            {cloudData?.map((item, index)=>{
                return <li key={index} style={{fontSize:getRandomFontSize(20, 40), color: (index+1)%5 === 0? "#ffcc4e" : (index+1)%4 === 0? "#1a6dff" : (index+1)%3 === 0? "#db492a" : (index+1)%2 === 0? "#ff80ab":"#00da8d" }}>{item}</li>
            })}
        </ul>
      </div>
      : null}
      {item?.type === 'Video'?
        <div className='Project_Video'>
          {/* {url?.split('=')[1]} */}
          <iframe width="100%" height="100%" src={`https://www.youtube.com/embed/${item?.title?.split('=')[1]}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      : null}
    </div>
  )
}

const url = "https://www.youtube.com/watch?v=8aahBio9Xqs"

function SlideSide({item, index, select, setSelect}){
  
  return(
    <div className='Project_Left_Slide_Con'>
        <div className={select === index? 'Project_Left_Slide Project_Left_Slide_Select':'Project_Left_Slide'} onClick={()=>setSelect(index)}>
          <p>{index+1}</p>
          {item?.type === "Text" && !item?.discr?
            <div style={{marginBottom:item.discr?0:10}} className='Project_Left_Slide_Heading'/>
          : item.discr?
            <div>
              <div className='Project_Left_Slide_Para'/>
              <div style={{height:8}} className='Project_Left_Slide_Para'/>
              <div style={{height:8, marginBottom:item?.title?10:0}} className='Project_Left_Slide_Para'/>
            </div>
          : item?.type === "Poll"?
            <ChartBar weight='fill' size={60} color='#ebebeb'/>
          :
            item?.type === "Info"?
            <QrCode weight='fill' size={60} color='#ebebeb'/>
          :
            item?.type === "Q&A"?
            <Question weight='fill' size={60} color='#ebebeb'/>
          : 
            item?.type === "Image"?
            <ImageSquare weight='fill' size={60} color='#ebebeb'/>
          : item?.type === "Cloud"?
            <Cloud weight='fill' size={60} color='#ebebeb'/>
          : item?.type === "Video"?
            <YoutubeLogo weight='fill' size={60} color='#ebebeb'/>:
          null
          }
          <h3>{item?.title}</h3>
        </div>
    </div>
  )
}

function ProjectRight({data, setSlides, index, slides, allData, datas, inpFocus, setInpFocus, setSelect, select, setImgSearch}){
  const params = useParams()
  const [bgType, setBgType] = useState("")
  const [titleStyle, setTitleStyle] = useState("")
  const [tab, setTab] = useState(false)

  const handleTitleChange = (event) => {
    const updatedData = slides?.map((item, itemIndex) => {
      if (itemIndex === index) {
        return { ...item, title: event.target.value };
      }
      return item;
    });
    setSlides(updatedData);
  };
  const bg = "dsds"

  const urlRegex = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
  
  var regex = new RegExp(urlRegex)
  
  useEffect(()=>{
    if(!data?.background){
      setBgType('None')
    }else if(data?.background?.match(regex)){
      setBgType('Image')
    }else{
      setBgType("Color")
    }
  },[data])

  useEffect(()=>{
    if(data?.background && bgType === 'None'){
      const updatedData = slides?.map((item, itemIndex) => {
        if (itemIndex === index) {
          return { ...item, background: '' };
        }
        return item;
      });
      setSlides(updatedData);
    }
  },[bgType])

  const handleDiscrChange = (event) => {
    const updatedData = slides?.map((item, itemIndex) => {
      if (itemIndex === index) {
        return { ...item, discr: event.target.value };
      }
      return item;
    });
    setSlides(updatedData);
  };

  const handleTypeChange = (event) => {
    const updatedData = slides?.map((item, itemIndex) => {
      if (itemIndex === index) {
        return { ...item, type: event };
      }
      return item;
    });
    setSlides(updatedData);
  };

  const handleBgChange = (event) => {
    const updatedData = slides?.map((item, itemIndex) => {
      if (itemIndex === index) {
        return { ...item, background: event.target.value };
      }
      return item;
    });
    setSlides(updatedData);
  };
  const styles = data?.titlestyle?.split(';') || []
  const titleFont = styles[0] || 'Arial';
  const titleSize = styles[1] || '20';
  const titleColor = styles[2] || '#000000';
  const titleAlign = styles[3] || 'Center';

  const handleTitleColorChange = (event) => {
    const updatedData = slides?.map((item, itemIndex) => {
      if (itemIndex === index) {
        return { ...item, titlestyle: `${titleFont};${titleSize};${event.target.value};${titleAlign};` };
      }
      return item;
    });
    setSlides(updatedData);
  };

  const handleTitleAlignChange = (event) => {
    const updatedData = slides?.map((item, itemIndex) => {
      if (itemIndex === index) {
        return { ...item, titlestyle: `${titleFont};${titleSize};${titleColor};${event};` };
      }
      return item;
    });
    setSlides(updatedData);
  };

  const handleTitleFontChange = (event) => {
    const updatedData = slides?.map((item, itemIndex) => {
      if (itemIndex === index) {
        return { ...item, titlestyle: `${event};${titleSize};${titleColor};${titleAlign};` };
      }
      return item;
    });
    setSlides(updatedData);
  };

  const handleTitleSizeChange = (event) => {
    const updatedData = slides?.map((item, itemIndex) => {
      if (itemIndex === index) {
        return { ...item, titlestyle: `${titleFont};${event};${titleColor};${titleAlign};` };
      }
      return item;
    });
    setSlides(updatedData);
  };

  const removeOption = (val) => {
    const opts = data?.options?.filter(e=>e !== val)
    const updatedData = slides?.map((item, itemIndex) => {
      if (itemIndex === index) {
        return { ...item, options: opts };
      }
      return item;
    });
    setSlides(updatedData);
  };

  const addOption = (val) => {
    const opts = [...data?.options, val]
    const updatedData = slides?.map((item, itemIndex) => {
      if (itemIndex === index) {
        return { ...item, options: opts };
      }
      return item;
    });
    setSlides(updatedData);
  };

  const editOption = (event, optionIndex) => {
    const { value } = event.target;
    setSlides(prevData => {
      const newData = [...prevData];
      newData[index] = { ...prevData[index] };
      newData[index].options = [...prevData[index].options];
      newData[index].options[optionIndex] = value;
      return newData;
    });
  };

  const QUERY = allData?.length === 0? CREATE_SLIDE : UPDATE_SLIDE

  const[onUpdate, {loading}] = useMutation(QUERY,{
    refetchQueries:[
      {
        query:GET_PRESENTATION,
        variables:{
          "getPresentationId":params?.presentId
        }
      }
    ]
  })

  function Update(){
    onUpdate({
      variables:{
        "slide": {
          "id": data?._id,
          "title": data?.title,
          "background": data?.background,
          "discr": data?.discr,
          "discrstyle": data?.discrstyle,
          "options": data?.options,
          "presentId": data?.presentId,
          "titlestyle": data?.titlestyle,
          "type": data?.type
        }
      }
    })
  }

  function Create(){
    onUpdate({
      variables:{
        "slide": {
          "id": "",
          "discrstyle": data?.discrstyle || "",
          "discr": data?.discr || "",
          "background": data?.background || "",
          "options": data?.options || "",
          "presentId": datas?.getPresentation?._id,
          "title": data?.title || "",
          "titlestyle": data?.titlestyle || "",
          "type": data?.type || 'Text'
        }
      }
    }).then(()=>{
      console.log('done');
    }).catch((err)=>{
      console.log(err);
    })
  }

  return(
    <div className='Project_Right'>
      <div className='Project_Right_Tab_Con' onClick={()=>setTab(!tab)}>
        <div className='Project_Right_Tab_Text'>
          <h3 style={{color:tab? "#9d9d9d" : "#000"}}>Details</h3>
          <h3 style={{color:tab? "#000" : "#9d9d9d"}}>Customise</h3>
        </div>
        <div className='Project_Right_Tab' style={{marginLeft:tab?"50%":"0%"}}/>
      </div>
      {tab === false?
      <>
      <p>Select type</p>
      <Select title={data?.type || "Select type"} onChange={handleTypeChange} data={[{name:"Text", value:"Text"},{name:"Image", value:"Image"},{name:"Q&A", value:"Q&A"},{name:"Multiple Answer", value:"Multiple"},{name:"Poll", value:"Poll"},{name:"Info", value:"Info"}]}/>
      <p>Slide items</p>
      {data?.type === "Video"? null : <input className='Project_Right_Input' onBlur={()=>setInpFocus('')} onFocus={()=>setInpFocus('Title')} disabled={data?.type === "Info"? true : false} value={data?.title} onChange={(event)=>handleTitleChange(event)} placeholder='Enter title'/>}
      {!data?.type || data?.type === "Text"?
      <>
      <textarea value={data?.discr} onBlur={()=>setInpFocus('')} onFocus={()=>setInpFocus('Discr')} onChange={(event)=>handleDiscrChange(event)} placeholder='Enter description'/>
      </>
      : null}
      {data?.type === "Poll"?
      <>
      {data?.options?.map((item, index)=>{
        return(
          <div key={index} className='Project_Right_Option'>
            <input className='Project_Right_Input1' value={item} onChange={(event)=>editOption(event, index)}/>
            <div className='FlexCon'/>
            <Trash className='Project_Right_Icon' onClick={()=>removeOption(item)}/>
          </div>
        )
      })}
      <button className='Project_Right_Del' onClick={()=>addOption(`Option ${data?.options?.length + 1}`)}>Add option<PlusCircle weight='fill'/></button>
      </>
      : data?.type === "Image"?
      <button className='Project_Right_Del'>Add Image</button>
      : data?.type === "Video"?
      <input className='Project_Right_Input' value={data?.title} onBlur={()=>setInpFocus('')} onChange={(event)=>handleTitleChange(event)} placeholder='Youtube URL'/>
      :
      null
      }
      </>
      :
      <>
      <p>Background</p>
      <Select title={bgType || "Select background"} onChange={setBgType} data={[{name:"Solid color", value:"Color"},{name:"Image", value:"Image"},{name:"None", value:"None"}]}/>
      {bgType === "Image"?
      <button className='Project_Right_Del' onClick={()=>setImgSearch(true)}>{data?.background?.match(regex)? "Change Image" : "Add Image"}</button>
      : bgType === "Color"?
      <input className='Project_Right_Input2' type='color' value={data?.background || "#ffffff"} onChange={(event)=>handleBgChange(event)}/>
      : null}
      <p>Title Style</p>
      <Select title={titleFont || 'Arial'} onChange={handleTitleFontChange} font={true} data={[{name:"Arial", value:"Arial"},{name:"Gill Sans", value:"Gill Sans"},{name:"Lucida Grande", value:"Lucida Grande"},{name:'Monospace', value:'monospace'},{name:"Times New Roman", value:"Times New Roman"},]}/>
      <div className='Project_Right_Style'>
        <Select style={{marginRight:10}} title={titleSize || '30'} onChange={handleTitleSizeChange} range={[10,50]}/>
        <input className='Project_Right_Input2' type='color' value={titleColor} defaultValue={"#000000"} onChange={(event)=>handleTitleColorChange(event)}/>
        <Select style={{marginLeft:10, flex:1}} title={titleAlign || 'Center'} onChange={handleTitleAlignChange} data={[{name:"Center", value:"Center"},{name:"Left", value:"Left"},{name:"Right", value:"Right"}]}/>
      </div>
      {data?.discr?
      <>
      <p>Description Style</p>
      <Select title={'Arial'} onChange={setTitleStyle} data={[{name:"Times New Roman", value:"Times New Roman"},{name:"Cursive", value:"Cursive"},{name:"Arial", value:"Arial"}]}/>
      <div className='Project_Right_Style'>
        <Select style={{marginRight:10}} title={'20'} onChange={setTitleStyle} data={[{name:"16", value:"16"},{name:"18", value:"18"},{name:"20", value:"20"}]}/>
        <input className='Project_Right_Input2' type='color' value={data?.background || "#ffffff"} onChange={(event)=>handleBgChange(event)}/>
        <Select style={{marginLeft:10, flex:1}} title={'Center'} onChange={setTitleStyle} data={[{name:"Center", value:"Center"},{name:"Left", value:"Left"},{name:"Right", value:"Right"}]}/>
      </div>
      </> : null}
      </>}
      <button className='Project_Right_Btn' style={{opacity:allData && allData[index]!==data?1: 0.6}} onClick={()=>allData?.length === 0? Create() : Update()}>{loading? "Loading" : "Save"}</button>
      {allData?.length === 0?
      null
      :
      <DeleteSlide id={data?._id} setSelect={setSelect} select={select}/>
      }
    </div>
  )
}

function DeleteSlide({id, setSelect, select}){
  const [del, setDel] = useState(false)
  const params = useParams()

  const[onDelete,{loading}] = useMutation(DELETE_SLIDE,{
    refetchQueries:[
      {
        query:GET_PRESENTATION,
        variables:{
          "getPresentationId":params?.presentId
        },
      }
    ],
  })

  function Delete(){
    onDelete({
      variables:{
        "deleteSlideId": id
      }
    })
    .then(()=>{
      if(select>0){
        setSelect(select - 1)
      }
      setDel(false)
    })
    .catch((e)=>{
      console.log(e);
    })
  }
  
  return(
    <>
    <button className='Project_Right_Btn1' onClick={()=>setDel(true)}>Delete</button>
    <Alerts loading={loading} show={del} setShow={setDel} title={"Are you sure?"} discr={'This action cannot undo!'} onclick={()=>Delete()}/>
    </>
  )
}

function CreateSlide({newOpen, data, setNewOpen, slides, setSelect}){
  const params = useParams()
  const[createSlide,{loading}] = useMutation(CREATE_SLIDE,{
    refetchQueries:[
      {
        query:GET_PRESENTATION,
        variables:{
          "getPresentationId":params?.presentId
        },
      }
    ],
  })
  function onCreate(type){
    createSlide({
      variables:{
        "slide": {
          "id": "",
          "discrstyle": "",
          "discr": "",
          "background": "",
          "options": type === 'Poll'? ['Option 1', 'Option 2'] : "",
          "presentId": data?.getPresentation?._id,
          "title": type === 'Video'? "" : type === 'Cloud'? 'Word Cloud' : `${type} Title`,
          "titlestyle": "",
          "type": type
        }
      }
    }).then((data)=>{
      if(slides?.length>0){
        setSelect(slides?.length)
      }else{
        setSelect(0)
      }
      setNewOpen(false)
    })
  }
  
  return(
    <div className={newOpen? 'Project_New_Modal Project_New_Modal_Show' : 'Project_New_Modal'}>
      <h3>Interation</h3>
      <div className='Project_New_Modal_Con'>
        <div className='Project_New_Modal_Item' onClick={()=>onCreate("Poll")}>
          <ChartBar weight='fill' size={30} color='#2e66ee'/>
          <p>Poll</p>
        </div>
        {/* <div className='Project_New_Modal_Item' onClick={()=>onCreate("Info")}>
          <QrCode weight='fill' size={30} color='#2e66ee'/>
          <p>Info</p>
        </div> */}
        <div className='Project_New_Modal_Item' onClick={()=>onCreate("Q&A")}>
          <Question weight='fill' size={30} color='#2e66ee'/>
          <p>Q&A</p>
        </div>
        <div className='Project_New_Modal_Item' onClick={()=>onCreate("MCQ")}>
          <Question weight='fill' size={30} color='#2e66ee'/>
          <p>MCQ</p>
        </div>
        <div className='Project_New_Modal_Item' onClick={()=>onCreate("Chart")}>
          <ChartDonut weight='fill' size={30} color='#2e66ee'/>
          <p>Chart</p>
        </div>
        <div className='Project_New_Modal_Item' onClick={()=>onCreate("Cloud")}>
          <Cloud weight='fill' size={30} color='#2e66ee'/>
          <p>Cloud</p>
        </div>
      </div>
      <h3 style={{marginTop:0}}>Presentation</h3>
      <div className='Project_New_Modal_Con'>
        <div className='Project_New_Modal_Item' onClick={()=>onCreate("Text")}>
          <TextAa weight='bold' size={30} color='#2e66ee'/>
          <p>Text</p>
        </div>
        <div className='Project_New_Modal_Item' onClick={()=>onCreate("Image")}>
          <Image weight='fill' size={30} color='#2e66ee'/>
          <p>Image</p>
        </div>
        <div className='Project_New_Modal_Item' onClick={()=>onCreate("Video")}>
          <Video weight='fill' size={30} color='#2e66ee'/>
          <p>Video</p>
        </div>
      </div>
      <div className={loading?'Project_New_Modal_Load Project_New_Modal_Load_Show':'Project_New_Modal_Load'}>
        Loading...
      </div>
    </div>
  )
}

const cloudData = [
  "Lorem", "ipsum", "dolor", "sit", "amet", "consectetur", "adipiscing", "elit", "sed", "do", "eiusmod", "tempor", "incididunt", "ut", "labore", "et", "dolore", "magna", "aliqua", "Ut", "enim", "ad", "minim", "veniam"
]