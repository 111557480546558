import { Plus, TrashSimple } from '@phosphor-icons/react'
import React, { useEffect, useState } from 'react'
import AWS from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid';
import { useMutation } from '@apollo/client';
import { UPDATE_USER } from '../graphql/mutation';
import { GET_USR } from '../graphql/query';
import imageCompression from 'browser-image-compression'
import Compressor from 'compressorjs';

AWS.config.update({
    region: 'eu-west-2',
    credentials: {
        accessKeyId: 'AKIA2ULA2P2XIQ45XBBK',
        secretAccessKey: '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',
    },
});

export default function ImageSearch({ open, setOpen, onChange, data }) {
    const s3 = new AWS.S3({ signatureVersion: 'v4' });
    const [img, setImg] = useState("")
    const [select, setSelect] = useState("")
    const [tab, setTab] = useState(false)
    const [result, setResult] = useState([])
    const [image, setImage] = useState("")

    const Access_Key = "ihi17fNjbfHQekV3MlTG0cZDU3fWPtCXZRKVHR3lTjU"

    const fetchRequest = async (e) => {
        e.preventDefault()
        if (img) {
            const data = await fetch(
                `https://api.unsplash.com/search/photos?page=2&query=${img}&client_id=${Access_Key}`
            );
            const dataJ = await data.json();
            const result = dataJ.results;
            console.log(result);
            setResult(result);
        }
    };

    useEffect(() => {
        if (result?.length > 0 && !img) {
            setResult([])
        }
    }, [img])

    const [imageId, setImageId] = useState("Hello")
    const [progress, setProgress] = useState(0)

    useEffect(() => {
        const uuids = uuidv4()
        setImageId(uuids)
    }, [image])

    async function OnUpload() {
        s3.putObject({
            Bucket: "xand",
            ACL: "public-read",
            Body: image,
            Key: `public/${imageId}.${image?.type.split("/", 2)[1]}`,
        })
            .on("httpUploadProgress", (evt) => {
                setProgress(Math.round((evt.loaded / evt.total) * 100));
                if ((evt.loaded / evt.total) * 100 === 100) {
                    UpdateUser()
                }
            })
            .send((err) => {
                if (err) console.log(err);
            });
    }

    const [userUpdate] = useMutation(UPDATE_USER, {
        refetchQueries: [
            { query: GET_USR }
        ]
    })

    function UpdateUser() {
        const img = `https://xand.s3.eu-west-2.amazonaws.com/public/${imageId}.${image?.type.split("/", 2)[1]}`
        const old = data?.getUser?.images || []
        userUpdate({
            variables: {
                "user": {
                    "images": [img, ...old],
                    "email": data?.getUser?.email,
                    "name": data?.getUser?.name
                }
            }
        }).then(() => {
            setImage("")
        })
    }

    async function OnFileSelect(e) {
        setSelect("")
        const imageFile = e.target.files[0];
        if((imageFile?.size / (1024 * 1024)).toFixed(2) < 1){
            console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
            setImage(imageFile)
        }else{
            const options = {
                maxSizeMB: 0.9,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
            }
            try {
                const compressedFile = await imageCompression(imageFile, options);
                console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);
                setImage(compressedFile);
            } catch (error) {
                console.log(error);
            }
        }
    }

    return (
        <div className={open ? 'Modal1 Modal_Show' : 'Modal1'}>
            <div className='Modal_Con1'>
                <div className='Modal_TabBar' onClick={() => setTab(!tab)}>
                    <div className='Modal_Tab_Text'>
                        <h3 style={{ color: tab ? "#9d9d9d" : "#000" }}>Search Image</h3>
                        <h3 style={{ color: tab ? "#000" : "#9d9d9d" }}>Upload Image</h3>
                    </div>
                    <div className='Modal_TabBar_Tab' style={{ marginLeft: tab ? "50%" : "0%" }} />
                </div>
                {tab ?
                    <div className='Modal_Body'>
                        <form onSubmit={(e) => {
                            e.preventDefault()
                            console.log(data)
                        }}>
                            <input value={img} onChange={(e) => setImg(e.target.value)} placeholder='Image URL' />
                            <button>Add</button>
                        </form>
                        <div className='Modal_Imgs'>
                            <div className='Modal_Img' style={{ display: "flex", alignItems: "center", justifyContent: "center" }} onClick={() => image ? setImage("") : null}>
                                {image ?
                                    <>
                                        <img src={URL.createObjectURL(image)} />
                                        <TrashSimple style={{ backgroundColor: "rgba(255, 255, 255, 0.4)", borderRadius: 10, padding: 5 }} className='Modal_Plus' size={25} color='red' />
                                    </>
                                    :
                                    <>
                                        <Plus className='Modal_Plus' size={25} color='#2e66ee' />
                                        <input onChange={(e) => OnFileSelect(e)} accept="image/*" type='file' />
                                    </>
                                }
                            </div>
                            {data?.getUser?.images?.map((item, index) => {
                                return <img key={index} style={{objectFit:"contain"}} className={select === item ? 'Modal_Img Modal_Img_Select' : 'Modal_Img'} src={item} onClick={() => setSelect(item)} />
                            })}
                        </div>
                    </div>
                    :
                    <div className='Modal_Body'>
                        <form onSubmit={(e) => fetchRequest(e)}>
                            <input value={img} onChange={(e) => setImg(e.target.value)} placeholder='Keyword' />
                            <button>Search</button>
                        </form>
                        {result?.length === 0 ?
                            <div className='Modal_Imgs'>
                                {sample?.map((item, index) => {
                                    return <img key={index} className={select === item ? 'Modal_Img Modal_Img_Select' : 'Modal_Img'} src={item} onClick={() => setSelect(item)} />
                                })}
                            </div>
                            :
                            <div className='Modal_Imgs'>
                                {result?.map((item, index) => {
                                    return <img key={index} className={select === item ? 'Modal_Img Modal_Img_Select' : 'Modal_Img'} src={item?.urls?.thumb} onClick={() => setSelect(item)} />
                                })}
                            </div>
                        }
                    </div>
                }
                <div className='Modal_BtnCon'>
                    {/* {(image?.size / (1024 * 1024)).toFixed(2) > 1 ? <p>Size of image you selected in {">"} 1 MB. This may slow down your presentation</p> : null} */}
                    <button className='Modal_Btn' onClick={() => setOpen(false)}>Cancel</button>
                    {tab && select ?
                        <button className='Modal_Btn1' style={{ opacity: select ? 1 : null }} onClick={() => onChange(select?.urls?.regular || select)}>Add</button>
                        : tab ?
                            <button className='Modal_Btn1' style={{ opacity: image ? 1 : null }} onClick={() => OnUpload()}>{progress > 0 && progress < 100 ? `Uploading ${progress}%` : "Upload"}</button>
                            :
                            <button className='Modal_Btn1' style={{ opacity: select ? 1 : null }} onClick={() => onChange(select?.urls?.regular || select)}>Add</button>
                    }
                </div>
            </div>
        </div>
    )
}

const sample = [
    "https://cdn.pixabay.com/photo/2020/12/26/13/19/christmas-background-5861438_1280.jpg",
    "https://cdn.pixabay.com/photo/2014/02/04/20/22/abstract-258339_1280.png",
    "https://cdn.pixabay.com/photo/2015/08/26/07/03/background-908172_1280.jpg",
    "https://cdn.pixabay.com/photo/2016/09/13/19/31/texture-1668079_1280.jpg",
    "https://cdn.pixabay.com/photo/2016/04/08/19/51/watercolor-1316867_1280.jpg",
    "https://images.pexels.com/photos/7130477/pexels-photo-7130477.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    "https://images.pexels.com/photos/7130540/pexels-photo-7130540.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    "https://images.pexels.com/photos/1323550/pexels-photo-1323550.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    "https://images.pexels.com/photos/1568607/pexels-photo-1568607.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    "https://images.rawpixel.com/image_1300/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvbHIvdHAxOTQtYXVkaS0wOS1nXzFfMi5qcGc.jpg",
    "https://images.rawpixel.com/image_1300/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvbHIvcm0xODQtdGFuZy0xMS1rLmpwZw.jpg",
    "https://images.rawpixel.com/image_1300/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvbHIvdjg1Ni1rdWwtMDdfMV8yLmpwZw.jpg",
    "https://images.rawpixel.com/image_1300/cHJpdmF0ZS9sci9pbWFnZXMvd2Vic2l0ZS8yMDIyLTA2L3YxMDQzLTA4NGMta3RzZDAyZWMuanBn.jpg",
    "https://images.rawpixel.com/image_1300/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvbHIvcm00MjgtMDA0OC5qcGc.jpg",
    "https://images.rawpixel.com/image_1300/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvbHIvcm00NzYtcGYtczEyNy1lLTAyNWEta3h1NmZucGQuanBn.jpg",
    "https://images.rawpixel.com/image_1300/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvbHIvcm00MzQtMDA3LWtzZHh1bjA4LmpwZw.jpg",
    "https://images.rawpixel.com/image_1300/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvbHIvdjExNDMtZnJhbWUxLTAxYS14XzEuanBn.jpg",
    "https://images.rawpixel.com/image_800/cHJpdmF0ZS9sci9pbWFnZXMvd2Vic2l0ZS8yMDIyLTA5L3JtMTgzLW51bm55LTEwLXguanBn.jpg"
]