import { gql } from "@apollo/client";

export const GET_PRESENTATION = gql`
query GetPresentation($getPresentationId: ID!) {
  getPresentation(id: $getPresentationId) {
    _id
    uuid
    code
    background
    template
    title
    slides {
      _id
      background
      discr
      discrstyle
      options
      presentId
      title
      titlestyle
      type
      answers {
        _id
        done
        response
      }
    }
  }
  getLive(id: $getPresentationId) {
    isLive
    currentSlide
    _id
  }
  getUser {
    _id
    email
    images
    name
  }
}`

export const GET_ONE_SLIDE = gql`
query GetSlide($getSlideId: ID!, $myAnswerId: ID) {
  getSlide(id: $getSlideId) {
    _id
    myAnswer(id: $myAnswerId) {
      _id
      done
      response
    }
  }
}`

export const GET_ALL_SLIDE = gql`
query GetSlide($getSlideId: ID!) {
  getSlide(id: $getSlideId) {
    _id
    answers {
      _id
      done
      response
    }
  }
}`

export const GET_PRESENT = gql`
query GetPresentation($getPresentationId: ID!, $getTempUserId: ID) {
  getPresentationCode(id: $getPresentationId) {
    _id
    uuid
    background
    template
    title
    slides {
      _id
      background
      discr
      discrstyle
      options
      presentId
      title
      titlestyle
      type
      myAnswer(id: $getTempUserId) {
        _id
        response
      }
    }
    live {
      currentSlide
      isLive
    }
  }
  getTempUser(id: $getTempUserId) {
    _id
    image
    name
    presentation
  }
}`

export const GET_PRESENT_USERS = gql`
query GetTempUsers($getTempUsersId: ID) {
  getTempUsers(id: $getTempUsersId) {
    _id
    image
    name
  }
}`

export const GET_USER = gql`
query GetUser($limit: Int){
  getUser {
    _id
    email
    name
    images
    presentations {
      _id
      title
      uuid
      code
      background
      slides(limit: $limit){
        title
        background
        titlestyle
      }
    }
  }
}
`

export const GET_USR = gql`
query GetUser {
  getUser {
    _id
    email
    name
    images
  }
}`

export const FIND_CODE = gql`
query Query($findCodeId: ID) {
  findCode(id: $findCodeId)
}`